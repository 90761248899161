/*
To use an icon, add the desired icon as a class to an SVG element. Do not modify icons directly in this file; any changes should be made in the relevant page files. This file is intended for reusable icons only.
Ex. use: <svg classname="icon--santa"/>
*/

/*MAP MARKERS----------------------------------------------------------------------------------------------------------------------*/
.icon--santa {
    background-image: url('~../icons/misc/santa-sleigh.svg');
    background-repeat: no-repeat;
}
.icon--node-access-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/NodeMarker.svg');
    background-repeat: no-repeat;
}
.icon--node-dist-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/NodeDistMarker.svg');
    background-repeat: no-repeat;
}
.icon--node-access-disabled-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/disabled/NodeDisabledMarker.svg');
    background-repeat: no-repeat;
}
.icon--node-dist-disabled-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/disabled/NodeDistDisabledMarker.svg');
    background-repeat: no-repeat;
}
.icon--moving-node-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/moving/MovingNodeMarker.svg');
    background-repeat: no-repeat;
}
.icon--moving-node-dist-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/moving/MovingNodeDistMarker.svg');
    background-repeat: no-repeat;
}
.icon--new-node-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/new/NewNodeMarker.svg');
    background-repeat: no-repeat;
}
.icon--cable-marker {
     background-image: url('~../icons/mapIcons/mapMarkers/CableMarker.svg');
     background-repeat: no-repeat;
 }
.icon--pipe-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/PipeMarker.svg');
    background-repeat: no-repeat;
}
.icon--homeownerassoc-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/HomeownerAssocMarker.svg');
    background-repeat: no-repeat;
}

.icon--homeownerassoc-netadmin-marker {
    background-image: url('~../icons/mapIcons/mapMarkers/NetadminHomeownerAssocMarker.svg');
    background-repeat: no-repeat;
}
/*LAYER ICONS-------------------------------------------------------------------------------------------------------------------*/

.icon--kum {
    background-image: url('~../icons/mapIcons/KumIcon.svg');
    background-repeat: no-repeat;
}

.icon--kum-wall {
    background-image: url('~../icons/mapIcons/KumWallBoxIcon.svg');
    background-repeat: no-repeat;
}

.icon--moving-kum {
    background-image: url('~../icons/mapIcons/mapMarkers/moving/MovingKumMarker.svg');
    background-repeat: no-repeat;
}

.icon--moving-kum-wall {
    background-image: url('~../icons/mapIcons/mapMarkers/moving/MovingKumWallBoxMarker.svg');
    background-repeat: no-repeat;
}

.icon--new-kum {
    background-image: url('~../icons/mapIcons/mapMarkers/new/NewKumIcon.svg');
    background-repeat: no-repeat;
}

.icon--nodes {
    background-image: url('~../icons/mapIcons/NodesIcon.svg');
    background-repeat: no-repeat;
}
.icon--node {
    background-image: url('~../icons/mapIcons/NodeIcon.svg');
    background-repeat: no-repeat;
}
.icon--node-dist {
    background-image: url('~../icons/mapIcons/NodeDistIcon.svg');
    background-repeat: no-repeat;
}
.icon--cable {
    background-image: url('~../icons/mapIcons/CableIcon.svg');
    background-repeat: no-repeat;
}
.icon--pipe {
    background-image: url('~../icons/mapIcons/PipeIcon.svg');
    background-repeat: no-repeat;
}
.icon--homeownerassoc {
    background-image: url('~../icons/mapIcons/HomeownerAssocIcon.svg');
    background-repeat: no-repeat;
}

.icon--homeownerassoc-netadmin {
    background-image: url('~../icons/mapIcons/NetadminHomeownerAssocIcon.svg');
    background-repeat: no-repeat;
}
/*BUTTON ICONS-----------------------------------------------------------------------------------------------------------------*/
.icon--white-edit {
    background-image: url('~../icons/buttonIcons/Edit_white.svg');
    background-repeat: no-repeat;
}
.icon--green-edit {
     background-image: url('~../icons/buttonIcons/Edit_green.svg');
     background-repeat: no-repeat;
}
.icon--green-search {
       background-image: url('~../icons/buttonIcons/Search_green.svg');
       background-repeat: no-repeat;
}
.icon--loading-white {
    background-image: url('~../icons/buttonIcons/Loading_white.svg');
    background-repeat: no-repeat;
}

.icon--loading-blue {
    background-image: url('~../icons/buttonIcons/Loading_blue.svg');
    background-repeat: no-repeat;
}

.icon--camera-white {
    background-image: url('~../icons/buttonIcons/Camera_white.svg');
    background-repeat: no-repeat;
}

.icon--camera-blue {
    background-image: url('~../icons/buttonIcons/Camera_blue.svg');
    background-repeat: no-repeat;
}

.icon--lock-open-white {
    background-image: url('~../icons/buttonIcons/LockOpen_white.svg');
    background-repeat: no-repeat;
}

.icon--lock-closed-blue {
    background-image: url('~../icons/buttonIcons/LockClosed_blue.svg');
    background-repeat: no-repeat;
}

.icon--delete-white {
    background-image: url('~../icons/buttonIcons/Delete_white.svg');
    background-repeat: no-repeat;
}

.icon--delete-red {
    background-image: url('~../icons/buttonIcons/Delete_red.svg');
    background-repeat: no-repeat;
}

.icon--plus-blue {
    background-image: url('~../icons/shapes/Plus_blue.svg');
    background-repeat: no-repeat;
}

.icon--trash-blue {
    background-image: url('~../icons/buttonIcons/Trash_blue.svg');
    background-repeat: no-repeat;
}

.icon--trash-red {
    background-image: url('~../icons/buttonIcons/Trash_red.svg');
    background-repeat: no-repeat;
}

.icon--send-green {
    background-image: url('~../icons/buttonIcons/Send_green.svg');
    background-repeat: no-repeat;
}

.icon--tag-blue {
    background-image: url('~../icons/buttonIcons/Tag_blue.svg');
    background-repeat: no-repeat;
}

/*ARROWS AND POINTERS--------------------------------------------------------------------------------------------------------------------*/
.icon--white-arrow {
    background-image: url('~../icons/arrowsAndPointers/Arrow_white.svg');
    background-repeat: no-repeat;
}

.icon--blue-arrow-down {
    background-image: url('~../icons/arrowsAndPointers/Arrow_blue.svg');
    background-repeat: no-repeat;
    rotate: 90deg;
}

.icon--white-chevron {
    background-image: url('~../icons/arrowsAndPointers/Chevron_white.svg');
    background-repeat: no-repeat;
}
.icon--black-chevron {
    background-image: url('~../icons/arrowsAndPointers/Chevron_black.svg');
    background-repeat: no-repeat;
}
.icon--blue-chevron {
    background-image: url('~../icons/arrowsAndPointers/Chevron_blue.svg');
    background-repeat: no-repeat;
}
.icon--blue-chevron-l {
    background-image: url('~../icons/arrowsAndPointers/Chevron_blue.svg');
    background-repeat: no-repeat;
    rotate: 270deg;
}
.icon--blue-chevron-r {
    background-image: url('~../icons/arrowsAndPointers/Chevron_blue.svg');
    background-repeat: no-repeat;
    rotate: 90deg;
}
.icon--blue-expand {
    background-image: url('~../icons/arrowsAndPointers/Expand_blue.svg');
    background-repeat: no-repeat;
}
.icon--blue-shrink {
    background-image: url('~../icons/arrowsAndPointers/Shrink_blue.svg');
    background-repeat: no-repeat;
}

/*DRAWING-----------------------------------------------------------------------------------------------------------------*/
.icon--drawing-start {
    background-image: url('~../icons/mapIcons/drawingMap/Circle.svg');
    background-repeat: no-repeat;

}
.icon--drawing-cursor {
    background-image: url('~../icons/mapIcons/drawingMap/Drawing_cursor.svg');
    background-repeat: no-repeat;
}

/*HEADER---------------------------------------------------------------------------------------------------------------------*/
.icon--profile {
    background-image: url('~../icons/header/Profile_blue.svg');
    background-repeat: no-repeat;
}
.icon--compass {
    background-image: url('~../icons/header/Compass_blue.svg');
    background-repeat: no-repeat;
}
.icon--list {
    background-image: url('~../icons/header/List_blue.svg');
    background-repeat: no-repeat;
    background-position: center center;
}
.icon--chat {
    background-image: url('~../icons/header/Chat_blue.svg');
    background-repeat: no-repeat;
}
.icon--chat-unread {
    background-image: url('~../icons/header/Chat_unread_blue.svg');
    background-repeat: no-repeat;
}
/*INFO MESSAGES ICONS-------------------------------------------------------------------------------------------------------------*/
.icon--message-info {
    background-image: url('~../icons/messageIcons/Info.svg');
    background-repeat: no-repeat;
}

.icon--message-warning {
    background-image: url('~../icons/messageIcons/Warning.svg');
    background-repeat: no-repeat;
}

.icon--message-error {
    background-image: url('~../icons/messageIcons/Error.svg');
    background-repeat: no-repeat;
}

.icon--message-success {
    background-image: url('~../icons/messageIcons/Success.svg');
    background-repeat: no-repeat;
}

.icon--new-message {
    background-image: url('~../icons/messageIcons/NewMessage.svg');
    background-repeat: no-repeat;
}
/*SHAPES------------------------------------------------------------------------------------------------------------------*/
.icon--circle-green {
    background-image: url('~../icons/shapes/Circle_green.svg');
    background-repeat: no-repeat;
}

.icon--circle-red {
    background-image: url('~../icons/shapes/Circle_red.svg');
    background-repeat: no-repeat;
}

/*MARKER SPESIFIC STYLES------------------------------------------------------------------------------------------------------*/
.icon--blue-download {
    background-image: url('~../icons/misc/download-blue.svg');
    background-repeat: no-repeat;
}

/*MISC-------------------------------------------------------------------------------------------------------------------------*/
.icon--blue-mouseclick-right {
    background-image: url('~../icons/misc/mouseClick_blue.svg');
    background-repeat: no-repeat;
}

.icon--blue-mouseclick-left {
    background-image: url('~../icons/misc/mouseClick_blue.svg');
    background-repeat: no-repeat;
    transform: scaleX(-1);
}