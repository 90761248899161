/*For all types of message components*/
/*Map popups*/

/*INFO MESSAGES-----------------------------------------------------------------------------------------------------------------------*/
.info-message {
    height: 60px;
    width: 100%;
    border-radius: 20px;
}

.info-message svg {
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-left: 10px;
}

.info-message p {
    margin: auto 0;
}

.info-message-info {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}

.info-message-warning {
    background-color: var(--light-yellow);
    color: var(--dark-yellow);
}

.info-message-error {
    background-color: var(--light-red);
    color: var(--dark-red);
}

.info-message-success {
    background-color: var(--light-green);
    color: var(--dark-green);
}

/*INFO BANNER-----------------------------------------------------------------------------------------------------------------------*/
.info-banner {
    position: absolute;
    top: 0;
    left: 0;
    height: 180px;
    width: 100%;
    z-index: 5000;
    color: var(--black);
    border-radius: 0 0 20px 20px;
    background-color: var(--light-yellow);
}

.info-banner--img {
    width: 150px;
    padding-bottom: 30px;
}

.info-banner--img img {
    height: 150px;
    width: 150px;
}

.info-banner--content {
    width: calc(100vw - 200px);
    padding: 30px 40px 40px 20px;
}

.info-banner--text {
    padding-left: 20px;
}

.info-banner--text h3 {
    margin-bottom: 10px;
}

.info-banner--close-btn {
    font-size: 14px;
    cursor: pointer;
    color: var(--dark-yellow);
    height: fit-content;
    filter: brightness(110%);
    width: 55px;
    text-wrap: nowrap;
}

.info-banner--close-btn:hover {
    filter: brightness(70%);
}

.info-banner--christmas {
    background-color: var(--red);
    color: var(--white) !important;
}
.info-banner--christmas .info-banner--close-btn {
    color: var(--white) !important;
}

.info-banner--christmas .info-banner--img {
    padding: 0;
    margin: 10px;
}

.info-banner--christmas .info-banner--close-btn:hover {
    filter: unset;
}

/*HELPING BOX-----------------------------------------------------------------------------------------------------------------------*/
.helping-box {
    background-color: var(--white);
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    padding: 8px;
    color: var(--obos-blue);
    text-align: center;
    width: 120px;
    position: absolute;
    z-index: 100000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    height: fit-content;
    right: 50px;
}

.helping-box p {
    font-size: 14px !important;
    margin: 0 !important;
}

.lock-button:hover + .helping-box,
.map--add-form-header button:hover + .helping-box {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

.helping-box--lock {
    margin-top: 5px;
    margin-right: 300px;
}

.helping-box--coordinates {
    margin-top: 50px;
    margin-right: 22px;
}

.helping-box--draw {
    margin-top: 50px;
    margin-right: 12px;
}

.helping-box--draw-poly {
    width: 151px !important;
    margin-top: 50px;
}

/*MAP POPUP -----------------------------------------------------------------------------------------------------------------------*/
.leaflet-popup-content-wrapper {
    background-color: var(--white) !important;
    width: 400px;
    height: fit-content;
}

.leaflet-popup-tip {
    background: var(--white) !important;
}

.leaflet-popup-close-button span {
    color: var(--black);
    font-weight: bold;
    font-size: 20px;
    padding: 10px 10px 0 0;
}

.map-popup-header {
    width: 100%;
    margin-bottom: 10px;
}

.map-popup-header h4 {
    font-weight: 700;
}

.map--popup svg {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.map--popup-body  {
    width: 350px !important;
}

.map--popup-info .long p {
    width: 175px;
}
.map--popup-info .long p:first-child {
    width: 75px;
}

.map--popup-info .tall p {
    width: calc(75px + 175px);
    min-height: 25px;
}

.leaflet-popup-content .map--popup-body p {
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.leaflet-popup-content button svg {
    width: 22px;
    height: 22px;
    margin: 0 0 0 5px;
}

.leaflet-popup-content button {
    font-size: 14px !important;
    padding: 0 !important;
}

.map--popup-list a {
    width: fit-content !important;
    margin-right: 10px !important;
    color: var(--black) !important;
    font-weight: 500;
    font-size: 14px;
}

.map--popup-list a:hover {
    text-decoration: none;
}

/*DELETE POPUP-----------------------------------------------------------------------------------------------------------------------*/
.delete-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.popup-opacity-background, .delete-popup--background-info {
    background: rgba(0, 0, 0, 0.5);
}

.delete-popup-content {
    background: var(--white);
    width: 500px !important;
    min-height: fit-content;
    max-height: 500px;
    padding: 30px;
    border-radius: 20px;
    text-align: center;

}

.delete-popup-content p {
    margin: 20px 0;
    color: var(--black);
}

.delete-popup-content button {
    font-size: 16px;
    margin: 10px;
    padding: 0 !important;
    width: 105px !important;
}

.delete-popup--relationships {
    text-align: left;
}

.delete-popup--relationships p {
    font-weight: bold;
    margin-top: 0;
}

/*CHOOSE PORT POPUPS-----------------------------------------------------------------------------------------------------------------------*/
.choose-port--background {
    position: absolute;
    width: calc(100% - 24vw);
    height: calc(100% - 85px);
    top: 90px;
    left: 24vw;
    z-index: 0;
}

.choose-port--message {
    background-color: var(--white);
    border-radius: 20px;
    width: 500px;
    height: 450px;
}

.choose-port--message p:not(.dropdown-item, .choose-port--chosen-ports .port), .choose-port--message select, .choose-port--message h3 {
    margin-bottom: 30px;
}

.choose-port--message p, .choose-port--message select {
    box-sizing: border-box;
    width: 400px;
}

.choose-port--message strong:not(.choose-port--message form strong) {
    color: var(--obos-blue);
}

.choose-port--message form p:first-of-type {
    margin: 0 !important;
}

.choose-port--chosen-ports {
    box-sizing: border-box;
    height: 50px;
    border-radius: 20px;
    border: 3px solid var(--white);
    padding-left: 10px;
    background-color: var(--white);
    margin-bottom: 20px;
    width: 415px;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.choose-port--chosen-ports .port {
    display: inline-block;
    width: fit-content;
}

.choose-port--chosen-ports .port:not(.choose-port--chosen-ports .port:first-of-type) {
    margin-left: 10px;
}

.choose-port--chosen-ports .port:hover {
    text-decoration: line-through;
    cursor: pointer;
}

.choose-port--select {
    width: 100%;
}

.choose-port--select button {
    height: 40px;
    margin-bottom: 20px !important;
}

.choose-port--buttons button {
    width: 105px;
    margin: 0 5px;
    height: 40px;
}

.choose-port--select .custom-select .dropdown-list {
    bottom: 80px;
}

/*CUSTOM ALERT-----------------------------------------------------------------------------------------------------------------------*/
.custom-alert {
    position: absolute;
    top: calc(90px + 25px);
    left: calc(25vw + 50px);
    z-index: 1000;
    background-color: white;
    height: 50px;
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    padding: 0 10px;
    color: var(--obos-blue);
}