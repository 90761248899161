/* FONT, SIZE AND WEIGHTS -----------------------------------------------------------------------------------------------------------------------*/

h1 {
    font: 700 28px/56px "OBOS-FONT", sans-serif;
}

h2, .info-page--name-input {
    font: 700 24px/42px "OBOS-FONT", sans-serif;
}

h3 {
    font: 700 20px/34px "OBOS-FONT", sans-serif;
}

h4, .map--add-nav-buttons button {
    font: 500 18px/28px "OBOS-FONT", sans-serif;
}

p, .btn-link, .input, input, select, option, textarea, .chat--search-box {
    font: 500 16px/28px "OBOS-FONT", sans-serif;
}

button {
    font: 600 16px/28px "OBOS-FONT", sans-serif;
}

label, .map--cluster-icon, .message--success p, .message--error p, a, .info-message p, .custom-alert p {
    font: 700 16px/28px "OBOS-FONT", sans-serif;
}

.date-font {
    font: 700 12px/24px "OBOS-FONT", sans-serif;
}

/* WEIGHTS-----------------------------------------------------------------------------------------------------------------------*/

.map--filter h3, .map--add h3, .profile--nav-buttons button {
    font-weight: 500;
}

.login h2, .info-banner h3 {
    font-weight: 700;
}

/* STYLES-----------------------------------------------------------------------------------------------------------------------*/

.italic, .date-font {
    font-style: italic;
}

/* COLORS-----------------------------------------------------------------------------------------------------------------------*/

label {
    color: var(--black) !important;
}