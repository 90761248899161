/*
Naming format: {page}--{component-type}-{optional details}.

Classes are sorted by their placement within the page.
Whenever possible, reuse existing classes across the page.
*/

.map {
    flex: 1;
    height: calc(100vh - 10vh);
}

.map--filter-comps {
    width: 100%;
}

/*REFRESH BUTTON ------------------------------------------------------------------------------------------------------*/
.map--filter .refresh-button {
    width: 200px;
}

.map--filter .refresh-button:hover {
    border: 3px solid var(--white);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.map--filter .refresh-button svg.spin {
    animation: spin 1s linear infinite;
}

/*SEARCH --------------------------------------------------------------------------------------------------------------*/
.map--filter-toggle h3 {
    margin-right: 10px;
    width: 175px;
}

/*SEARCH------------------------------------------------*/
.map--search, .map--search-address {
    margin-bottom: 15px;
    width: 100%;
}

.map--search input, .map--search-address input {
    all: unset;
    max-width: 250px;
    min-width: 100px;
    height: 50px;
    border: unset;
    width: 100%;
}

.map--search .input, .map--search-address .input {
    border-radius: 30px;
    height: 50px;
    box-sizing: border-box;
    width: 100%;
}

.map--search .input input:focus, .map--search-address .input input:focus {
    outline: none !important;
    border: none !important;
}

.map--search .input,
.map--search-address .input,
.map--search-results {
    color: var(--black);
    margin-bottom: 20px;
    min-width: 150px;
    background-color: var(--white);
}

.map--search .input svg,
.map--search-address .input svg{
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border: 3px solid var(--white);
    border-radius: 100%;
}

.map--search-results {
    padding: 10px;
    border-radius: 25px;
    max-height: 575px;
}

.map--search-results .results-header p:nth-child(2) {
    color: var(--obos-green);
    font-weight: 700;
}

.map--search-results .results-header {
    margin-bottom: 5px;
}

.results-responses {
    max-height: 525px;
    overflow-y: scroll;
}

.map--search-results .map--search-result {
    margin-bottom: 5px;
    text-decoration: none;
    color: var(--black);
}

.map--search-result div {
    width: fit-content;
}

.map--search-results a {
    color: var(--black);
    text-decoration: none;
}

.map--search-results a:hover, .map--search-results p:hover:not(.results-header p) {
    color: var(--obos-green);
    font-weight: bold;
    cursor: pointer !important;
}

/*MAP FILTER AND HELP-----------------------------------------------------------------------------------------------*/
.map--filter-comps h3, .map--filter-toggle .switch {
    margin-bottom: 10px;
}

.map--side-table-header {
    width: 100%;
    cursor: pointer;
}

.map--side-table-header svg {
    margin-right: 8px;
}

.map--side-table-container {
    min-height: 0;
    max-height: 1000px;
    width: 100%;
    margin-bottom: 15px;
    overflow: hidden;
    transition: max-height 1.0s ease-in-out;
}

.map--side-table-container::-webkit-scrollbar {
    display: none;
}

.map--side-table-closeable {
    box-sizing: border-box;
    min-height: 50px;
    margin-bottom: 15px;
    width: 100%;
    border-radius: 25px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-right: 5px;
    border: var(--white) solid 3px;
}

.map--side-table-closeable.help {
    background-color: var(--white);
    color: var(--black);
}

.map--side-table-closeable.help h4 {
    color: var(--obos-blue);
}

.map--side-table-closeable .help--content {
    width: 100%;
    height: fit-content;
}

.map--side-table-closeable .help--content div {
    margin-top: 5px;
}

.map--side-table-closeable .help--content p {
    width: calc(100% - 40px);
}

.map--side-table-closeable .help--content svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.map--side-table-closeable .help--content .icon--homeownerassoc-netadmin {
    margin-top: 5px;
    margin-bottom: auto;
}

.map--side-table-closeable .map--filter--checkbox, .map--side-table-closeable.help .help--content {
    min-height: 0;
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 1.0s ease-in-out;
}

.map--side-table-closeable h4 {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.map--side-table-closeable h4 svg, .map--side-table-header svg {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
}

.map--side-table-header svg {
    margin-bottom: 10px;
}

/*SIDE TABLE ClOSE STATE ------------------------------------------------*/
.map--side-table-closeable.close svg:not(.help--content div svg), .map--side-table.close .map--side-table-header svg {
    transform: rotate(-180deg);
    transition: transform 0.5s ease-in-out;
}

.map--side-table-closeable.close .map--filter--checkbox,
.map--side-table-closeable.help.close .help--content,
.map--side-table.close .map--side-table-container  {
    max-height: 0 !important;
    transition: max-height 1.0s ease-in-out;
    overflow-y: hidden;
}

.map--side-table-closeable.close:hover {
    background-color: var(--dark-blue);
}

.map--side-table-closeable.help.close:hover {
    background-color: var(--white);
    border: var(--dark-blue) solid 3px;
}

/*FILTER------------------------------------------------*/
.map--filter {
    box-sizing: border-box;
    background-color: var(--obos-blue);
    color: var(--white);
    height: 92vh;
    width: 25vw;
    position: absolute;
    z-index: 1;
    overflow-y: scroll;
    border-radius: 0 20px 0 0;
}

.map--filter::-webkit-scrollbar {
    display: none;
}

.map--filter--checkbox label {
    cursor: pointer;
    color: var(--white) !important;
    font-weight: 500 !important;
}

/*HELP ------------------------------------------------*/
.map--help {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--white);
    border-radius: 25px;
    padding: 10px;
    color: var(--black);
    max-height: 50vh;
    overflow-y: scroll;
}

.map--help-text1 {
    margin-bottom: 10px;
}

.map--help-text1 p {
    width: calc(100% - 50px);
    margin-left: 10px;
}

.map--help-icons {
    width: 100%;
    margin-top: 10px;
}

.map--help-icons div {
    width: 100px;
}

.map--help-circle {
    box-sizing: border-box;
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
}
.map--help-circle.blue {
    border: solid 3px var(--homeownerAssoc-border);
    background-color: var(--homeownerAssoc-opacity);
}
.map--help-circle.green {
    border: solid 3px var(--circle-green);
    background-color: var(--circle-green-opacity);
}
.map--help-circle.orange {
    border: solid 3px var(--circle-orange);
    background-color: var(--circle-orange-opacity);
}
.map--help-circle.red {
    border: solid 3px var(--circle-red);
    background-color: var(--circle-red-opacity);
}


/*MAP ITSELF -----------------------------------------------------------------------------------------------------------*/
.big-map {
    position: absolute;
    height: calc(106vh - 90px);
    width: 77vw;
    z-index: 0;
    top: 90px;
    left: 23vw;
    right: 0;
    bottom: 0;
}

.big-map .leaflet-control-zoom {
    display: none;
}

/*MAP ADD OBJECT -------------------------------------------------------------------------------------------------------*/
.map--add {
    overflow: hidden;
    width: 100vw;
    height: 80vh;
    position: absolute;
    z-index: 1;
    border-radius: 20px 20px 0 0;
    background-color: var(--white);
}

/*MAP ADD OBJECT NAV ---------------------------------------------------------------------------------------------------*/
.map--add-nav {
    background-color: var(--obos-green);
    color: var(--white);
    width: 25vw;
    height: 100%;
    padding: 40px 0;
    box-sizing: border-box;
}

.map--add-nav div {
    width: 100%;
    height: 100%;
    padding: 25px 0;
}

.map--add-nav h3 {
    width: 80%;
    height: 40px;
    display: flex;
    align-items: center;
}

.map--add-nav-buttons {
    box-sizing: border-box;
    margin: 25px 0 75px 0;
    width: 80% !important;
}

.map--add-nav-buttons button {
    min-width: 150px;
    width: 100%;
}
/*MAP ADD OBJECT FORMS -------------------------------------------------------------------------------------------------*/
.map--no-add-form, .map--add-form {
    padding: 40px 50px 20px 50px;
    box-sizing: border-box;
    height: 80vh;
    width: 75vw;
}

.map--add-form-header {
    box-sizing: border-box;
    height: 40px;
    margin: 0 !important;
}

.map--add-form-header h3, .map--no-add-form h3 {
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 40px;
}

.map--add-form-header button {
    box-sizing: border-box;
    height: 40px;
    width: 180px;
    display: flex;
    justify-content: space-between;
}

.map--add-form-message {
    min-height: 50px !important;
    width: 100%;
}

.map--add-form-message .info-message {
    height: 50px;
}

.map--add-form form {
    padding: 20px 0 40px 0;
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.map--add-form-inputs {
    width: 100%;
    height: 100%;
    align-items: flex-end;
}

.map--add-form-input-row {
    width: 100%;
}

.map--add-form-input-container {
    width: 48%;
}

.map--add-form-input-container .checkbox {
    box-sizing: border-box;
    min-height: 50px;
    max-height: 50px;
}

.map--add-form-input-container.message {
    justify-content: flex-end;
    height: 100%;
}

.map--add-form-input-container.message .info-message {
    height: 50px;
}

.map--add-form-input-container.message .info-message p {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    width: 80%;
}

.whole-row {
    width: 100%;
}

.map--add-form label {
    margin-top: 10px;
    width: 100%;
}

.map--add-form input, .map--add-form select {
    margin: 0 !important;
    width: 100%;
}

.map--add-form form button {
    bottom: 0;
    width: 100px;
    margin: 0 5px;
}

.map--add-form .checkbox {
    height: 55px;
}

.map--add-form input[type="checkbox"] {
    width: 22px;
    height: 22px;
    border: 3px solid var(--light-grey);
}

.map--add-ports-display {
    width: 48%;
    box-sizing: border-box;
    height: 50px;
    border-radius: 20px;
    border: 3px solid var(--white);
    padding-left: 10px;
    background-color: var(--white);
    margin-bottom: 20px;
    resize: none;
}

.map--add-ports-select {
    margin-bottom: 20px;
    width: 48%;
}

.map--add-ports-handling {
    max-width: 48%;
    min-width: 120px;
    margin-right: 0;
}

.map--add-ports-select {
    width: 100%;
}

.map--add-ports-select .dropdown-list {
    width: 100%;
}

.map--add-ports-buttons {
    margin-bottom: 20px;
    height: 50px;
}

.map--add-ports-buttons button, .map--add-ports-select button {
    width: 110px !important;
    height: 40px;
}

.map--add-ports-select button {
    margin: 0 0 0 10px !important;
}

.map--add-ports-buttons button {
    box-sizing: border-box;
    margin: 0 0 0 10px !important;
}

/*MAP UPDATE COORDINATES BUTTONS -------------------------------------------------------------------------------------------*/
.map--update-coordinate--buttons {
    padding: 10px;
    position: absolute;
    top: calc(100vh - 10vh);
    left: calc(25vw + 50px);
    z-index: 1000;
    pointer-events: auto;
}

.map--update-coordinate--buttons button {
    margin-right: 10px;
}

.map--update-coordinate--help-box {
    color: var(--white);
    position: absolute;
    top: calc(100vh - 10vh);
    right: 50px;
    z-index: 1000;
    pointer-events: auto;
    max-height: 42px;
    width: 225px;
}

.map--update-coordinate--help-box h4 {
    border-radius: 25px;
    width: 42px;
    height: 42px;
    font-weight: 700;
    cursor: help;
    margin-right: 0;
    margin-left: auto;
}

.map--update-coordinate--help-box h4,
.map--update-coordinate--help-box:hover .map--update-coordinate--help-box-text {
    box-sizing: border-box;
    background-color: var(--obos-blue);
    border: 3px solid var(--white);
    padding: 10px;
}

.map--update-coordinate--help-box-text {
    max-height: 0;
    overflow: hidden;
}
.map--update-coordinate--help-box:hover {
    max-height: 300px;
}
.map--update-coordinate--help-box:hover .map--update-coordinate--help-box-text {
    max-height: 300px;
    max-width: 225px;
    position: absolute;
    top: -200px;
    border-radius: 25px;
}