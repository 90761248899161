/*
Naming format: {page}--{component-type}-{optional details}.

Classes are sorted by their placement within the page.
Whenever possible, reuse existing classes across the page.
*/

.info-page {
    box-sizing: border-box;
    height: 90vh;
    width: 100vw;
}

.info-page--map {
    position: absolute;
    width: 25vw;
    z-index: 0;
    top: 90px;
    height: calc(100% - 90px) !important;
    margin: 0 !important;
}

.info-page--content {
    box-sizing: border-box;
    margin-left: 22vw;
    width: 78vw;
    padding: 50px 50px 0 50px;
    height: 90vh;
    position: relative;
    z-index: 1;
    background-color: var(--white);
    border-radius: 20px 0 0 20px;
}

.info-page--content-header,
.info-page--log,
.info-page--data,
.info-page--connections,
.info-page--connections-nodes,
.info-page--switches,
.info-page--locked {
    margin-bottom: 20px;
}

/*HEADER --------------------------------------------------------------------------------------------------------------------*/
.info-page--content-header {
    width: 100%;
    padding: 0 10px;
    height: fit-content;
}
.info-page--content-header-buttons .btn-primary-white-bg {
    width: 200px;
}

.info-page--content-header-buttons .btn-secondary-white-bg {
    width: 150px;
}
.info-page--content-header .icon--lock-open-white,
.info-page--content-header .icon--lock-closed-blue {
    width: 16px;
    height: 16px;
}
.info-page--content-header .subscribe-container {
    width: 100%;
    display: flex;
    justify-content: right;
}

.info-page--content-header .subscribe {
    margin-top: 15px;
    width: fit-content;
}

.info-page--content-header .subscribe h4 {
    font-weight: bold;
    margin-right: 10px;
}

.info-page--content-header .subscribe.disabled h4 {
    color: var(--disabled-grey)
}

.info-page--content-header .subscribe.disabled .info-text {
    display: none;
}

.info-page--content-header .subscribe.disabled:hover .info-text {
    display: inline-block;
    position: absolute;
    background-color: var(--white);
    height: fit-content;
    font-size: 16px;
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    width: 200px;
    top: 105px;
    padding: 10px;
    right: 180px;
    color: var(--obos-blue);
    text-align: center;
}

.info-page--content-header .subscribe.disabled .switch input, .info-page--content-header .subscribe.disabled .switch span {
    cursor: not-allowed !important;
}

.info-page--content-header .subscribe.disabled .switch input:checked + .slider {
    background-color: var(--disabled-green);
}


/*CONTENT DATA---------------------------------------------------------------------------------------------------------*/
.info-page--content-data {
    overflow: scroll;
    padding: 0 10px;
    height: 100%;
    width: 100%;
}

.info-page--content-data .info-message {
    margin-bottom: 10px;
}

/*COMMON----------------------------------------------------------*/
.info-page--log, .info-page--data, .info-page--files, .info-page--connections, .info-page--switches, .info-page--connections-nodes {
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
}

.info-page--log-headers, .info-page--data-headers, .info-page--files, .info-page--connections-headers, .info-page--switches-headers, .info-page--connections-nodes-headers {
    background-color: var(--light-blue);
}

.info-page--log-headers p, .info-page--data-headers p, .info-page--files p,
.info-page--connections .count,
.info-page--connections-nodes-headers p,
.info-page--switches-headers p {
    font-weight: 700;
}

.info-page--log p, .info-page--data p, .info-page--connections p, .info-page--connections-nodes p, .info-page--switches-headers p, .info-page--switches-headers {
    height: 70px !important;
    display: flex;
    align-items: center;
}

/*NAME----------------------------------------------------------*/
.info-page--name svg, .info-page--name-input svg {
    width: 70px;
    height: 70px;
}

.info-page--name svg {
    margin-right: 33px;
    border-radius: 50px;
}

.info-page--name-input svg {
    margin-right: 20px;
    border-radius: 50px;
}

.info-page--name .icon--kum-wall, .info-page--name-input .icon--kum-wall {
    border-radius: 10px;
}

.info-page--name, .info-page--name-input input {
    box-sizing: border-box;
    height: 70px;
    margin: 0 !important;
}

.info-page--name-input input {
    font-size: 24px;
    height: 50px;
    font-weight: 700;
    width: 400px;
}

/*LOG ----------------------------------------------------------*/
.info-page--log-headers {
    border-radius: 20px 20px 0 0 ;
}

.info-page--log-data-row, .info-page--log-headers {
    width: 100%;
}

.info-page--log-data-row p, .info-page--log-headers p {
    justify-content: center;
}

.info-page--log-headers p:nth-child(1), .info-page--log-data-row p:nth-child(1),
.info-page--log-headers p:nth-child(4), .info-page--log-data-row p:nth-child(4)
{
    width: 175px !important;
}

.info-page--log-headers p:nth-child(2), .info-page--log-data-row p:nth-child(2) {
    width: 150px;
}

.info-page--log-headers p:nth-child(3), .info-page--log-data-row p:nth-child(3) {
    width: calc(100% - (175px * 2) - 150px);
}

.info-page--log-data {
    max-height: 280px;
    overflow-y: scroll;
}

/*DATA ----------------------------------------------------------*/
.info-page--data-headers {
    box-sizing: border-box;
    border-radius: 20px 0 0 20px;
    padding-left: 20px;
}

.info-page--data-headers p {
    box-sizing: border-box;
    width: 150px;
}

.info-page--data-data {
    box-sizing: border-box;
    padding-left: 20px;
}

.info-page--data-data p {
    box-sizing: border-box;
    width: 310px;
}

/*FILES ----------------------------------------------------------*/
.info-page--files {
    width: 500px;
    height: 420px;
}

.info-page--files.noImages {
    height: 100px;
    justify-content: center;
}

.info-page--files label {
    margin: 0 !important;
}

.info-page--files img {
    width: 300px;
    height: 250px;
    margin-bottom: 10px;
    border-radius: 20px;
}

.info-page--files img, .info-page--files-image-buttons, .info-page--files-pdf-buttons {
    width: 300px;
}

.info-page--files-image {
    padding-top: 10px;
}

.info-page--files-pdf {
    padding-bottom: 10px;
}

.info-page--files-image-buttons,
.info-page--files-pdf-buttons {
    background-color: var(--white);
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 20px;
}

.info-page--files-pdf svg:not(.chevron), .info-page--files-image svg:not(.chevron) {
    margin: 0 5px;
}

input[type="file"], .info-page--files-fullscreen-container input[type="file"], .info-page--files svg:not(.chevron) {
    display: none;
}

.info-page--files-pdf:hover svg, .info-page--files-image:hover svg {
    display: block;
}

/*SVG SIZES*/
.info-page--files-image-buttons label,
.info-page--files-pdf-buttons label,
.info-page--files-fullscreen-buttons label {
    margin: 0 !important;
}

.info-page--files .chevron,
.info-page--files-fullscreen-buttons .icon--blue-chevron-r,
.info-page--files-fullscreen-buttons .icon--blue-chevron-l {
    width: 30px;
    height: 30px;
}

.info-page--files .icon--plus-blue, .info-page--files-fullscreen-buttons .icon--plus-blue {
    width: 18px;
    height: 18px;
}

.info-page--files .icon--blue-expand,
.info-page--files-fullscreen-buttons .icon--blue-shrink,
.info-page--files-fullscreen-buttons .icon--blue-download,
.info-page--files .icon--blue-download
{
    width: 20px;
    height: 20px;
}

.icon--trash-blue {
    width: 28px;
    height: 28px;
}

/*SVG HOVER*/
/*Darker blue*/
.info-page--files .blue-svg:hover,
.info-page--files-fullscreen-buttons .icon--blue-chevron-r:hover,
.info-page--files-fullscreen-buttons .icon--blue-chevron-l:hover,
.info-page--files-fullscreen-buttons .icon--blue-shrink:hover,
.info-page--files-fullscreen-buttons .icon--blue-download:hover {
    filter: brightness(60%);
    cursor: pointer;
}

/*Green*/
.info-page--files .icon--plus-blue:hover, .icon--plus-blue:hover{
    filter: invert(63%) sepia(100%) saturate(543%) hue-rotate(115deg) brightness(50%) contrast(91%);
    cursor: pointer;
}

/*Red*/
.info-page--files .icon--trash-blue:hover,
.info-page--files-fullscreen-buttons .icon--trash-blue:hover {
    filter: saturate(100%) invert(21%) sepia(98%) saturate(5635%) hue-rotate(347deg) brightness(60%) contrast(118%);
    cursor: pointer;
}

/*Grey-Blue ish*/
.info-page--files svg.disabled,
.info-page--files-fullscreen-buttons .icon--plus-blue.disabled,
.info-page--files-fullscreen-buttons .icon--blue-chevron-l.disabled,
.info-page--files-fullscreen-buttons .icon--blue-chevron-r.disabled,
.info-page--files-fullscreen-buttons .icon--trash-blue.disabled
{
    filter: saturate(60%) !important;
    opacity: 50%;
    cursor: not-allowed !important;
}

/*FULL SCREEN MODE*/
.info-page--files-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}

.info-page--files-fullscreen-buttons {
    background-color: var(--light-blue);
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    margin-top: 20px;
    width: 700px;
    z-index: 1000;
    position: absolute;
    bottom: 50px;
}
.info-page--files-fullscreen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-page--files-fullscreen-container img {
    border-radius: 20px;
    cursor: zoom-in;
    object-fit: contain;
    max-width: 1024px;
    max-height: 550px;
    transition: transform 0.3s ease;
}

.info-page--files-fullscreen-container img.zoomed-in {
    transform: scale(3);
    transition: transform 0.3s ease;
    cursor: zoom-out;
}

.info-page--files-fullscreen-container .pdf {
    height: 600px;
    width: 670px;
}

.info-page--files-fullscreen-update-buttons svg {
    margin: 0 10px;
}

/*CONNECTIONS ----------------------------------------------------------*/
.info-page--connections-headers {
    box-sizing: border-box;
    border-radius: 20px 0 0 20px;
    padding-left: 20px;
}

.info-page--connections-headers p {
    box-sizing: border-box;
    width: 150px;
}

.info-page--connections-data {
    box-sizing: border-box;
    padding: 0 40px 0 20px;
    width: 100%;
}

.info-page--connections-data a {
    color: var(--black);
    font-weight: 500;
    height: 70px;
    display: flex;
    align-items: center;
}

.info-page--connections-data a:not(.info-page--connections-data a:first-child) {
    padding-left: 20px;
}

.info-page--connections-data a:hover {
    color: var(--obos-blue);
    text-decoration: none;
}

.info-page--connections-data .count {
    color: var(--obos-blue);
}

.info-page--connections-update-nodes {
    height: 70px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.info-page--connections-update-nodes select {
    box-sizing: border-box;
    width: 200px;
    height: 70px !important;
    border: solid 3px var(--white);
    margin: 0 10px 0 0!important;
}

.info-page--connections-update-nodes button {
    height: 50px;
    border-radius: 25px!important;
    font-size: 16px;
    width: 165px;
}

.info-page--connections .info-page--connections-update-nodes * {
    margin-right: 10px;
}

.info-page--connections-update-nodes .add-node--searchable-dropdown {
    position: relative;
}

.info-page--connections-update-nodes .add-node--searchable-dropdown input {
    box-sizing: border-box;
    height: 70px !important;
    margin-bottom: 0 !important;
    width: 200px !important;
    margin-right: 10px !important;
}

.info-page--connections-update-nodes .add-node--searchable-dropdown .dropdown-list {
    position: absolute;
    box-sizing: border-box;
    bottom: 80px;
    background-color: var(--white);
    border-radius: 20px;
    border: solid 3px var(--obos-blue);
    padding: 10px;
    width: 200px;
    max-height: 200px;
    overflow-y: scroll;
}

.info-page--connections-update-nodes .add-node--searchable-dropdown .dropdown-item {
    color: var(--obos-blue);
    font-weight: bold;
    cursor: pointer;
}

.add-node--searchable-dropdown .dropdown-list .dropdown-item:hover {
    color: var(--obos-green);
}

.add-node--searchable-dropdown .dropdown-list .dropdown-item.disabled {
    cursor: not-allowed;
    color: var(--disabled-blue);
}

/*SWITCHES*/
.info-page--switches-headers {
    cursor: pointer;
    height: 30px;
    border-radius: 20px;
    padding: 0 20px;
}

.info-page--switches-content {
    max-height: 0;
    overflow-y: scroll;
    transition: max-height  1.5s ease;
}

.info-page--switches-data {
    padding: 20px 20px 0 20px;
}

.info-page--switches-data div p {
    padding: 10px 20px;
}

.info-page--switches-headers svg {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.info-page--switches-port-info {
    width: 100%;
}

.info-page--switches-port-info div {
    width: calc(25% - 10px);
}

.info-page--switches-port-info p {
    width: 100%;
    text-align: left;
    padding-left: 0 !important;
}

.info-page--switches-port-info svg {
    width: 10px;
    height: 10px;
    margin: auto 10px auto 10px;
}

/*ACTIVE*/
.info-page--switches.active .info-page--switches-content {
    height: fit-content;
    max-height: 600px;
}

.info-page--switches.active .info-page--switches-headers {
    border-radius: 20px 20px 0 0;
}

.info-page--switches.active .info-page--switches-headers svg {
    transform: rotate(360deg);
}

.info-page--switches ::-webkit-scrollbar {
    display: none;
}

/*CONTENT EDIT ---------------------------------------------------------------------------------------------------------*/
.info-page--edit-info input,
.info-page--edit-info-row-input,
.info-page--edit-info select {
    width: 45%;
}

.info-page--edit-info .wide-input, .info-page--edit-info-row input, .info-page--edit-info-row select {
    width: 100%;
}

.info-page--edit-info-row {
    width: 100%;
}

.info-page--locked {
    box-sizing: border-box;
    border: 3px solid var(--obos-blue);
    background-color: var(--light-blue);
    height: 60px;
    border-radius: 20px;
}

.info-page--locked p {
    font-weight: 700;
    color: var(--obos-blue);
}

.info-page--locked svg {
    width: 20px;
    height: 20px;
    margin: 0 20px;
}