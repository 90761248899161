.login {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: transparent;
}

.login--content {
    background-color: var(--white);
    border-radius: 0 20px 20px 0;
    position: relative;
    z-index: 1;
    padding: 20px;
    width: 100%;
}

.login--background-image {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-image: url('../../images/LoginImage.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 0;
}

.login--message {
    height: 100px;
}

.login--form {
    width: 100% !important;
}

.login--form input,
.login--form h2,
.login--message,
.login--form label {
    width: 60%;
}

.login--form *:not(label):not(button) {
    margin-bottom: 40px;
}