/*
Naming format: {page}--{component-type}-{optional details}.

Classes are sorted by their placement within the page.
Whenever possible, reuse existing classes across the page.
*/

.list {
    display: flex;
    overflow: hidden;
}

.list--map {
    position: absolute;
    width: 25vw;
    z-index: 0;
    top: 90px;
    height: calc(100% - 90px) !important;
}

.list--content-container {
    margin-left: 22vw;
    overflow: hidden;
    height: calc(100vh - 90px);
    position: absolute;
    z-index: 1;
    background-color: var(--white);
    border-radius: 20px 0 0 20px;
}

.list--content {
    padding: 25px 50px;
    overflow-y: scroll;
    height: calc(100% - 50px);
}

.list--header button svg {
    width: 24px;
}

/*LIST STATISTICS ------------------------------------------------------------------------------------------------------*/
.list--statistics {
    padding: 10px 20px;
    margin-top: 20px;
}

.list--statistics p {
    font-size: 14px;
}

.list--statistics b {
    color: var(--obos-blue);
}

/*LIST CLOSEABLE ------------------------------------------------------------------------------------------------------*/
.list--closeable {
    padding: 20px;
    margin-top: 20px;
    overflow: hidden;
}

/*OPEN STATE---------------------------------------------------*/

.list--closeable.open {
    overflow: scroll;
}

.list--closeable.open .list--closeable-container {
    height: fit-content;
    max-height: 500px;
    transition: max-height ease-in-out 1.0s;
    margin-bottom: 10px;
}

.list--closeable.open .list--closeable-header {
    margin-bottom: 15px;
}

.list--closeable.open .list--closeable-header svg:not(h3 svg) {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.list--closeable.open::-webkit-scrollbar {
    display: none;
}

/*HEADERS-------------------------------------------------------------*/
.list--closeable-header {
    height: fit-content;
    transition: margin-bottom ease-in-out 0.5s;
    cursor: pointer;
}

.list--closeable-header h3 svg,
.list--closeable-header svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.list--closeable-header svg:not(h3 svg) {
    transition: transform 0.5s ease;
    rotate: 180deg;
}

/*TABLE-----------------------------------------------------------------*/
.list--closeable-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height ease-in-out 1.0s;
    width: 100%;
    margin: 0 !important;
}

.list--closeable-container div {
    width: 100%;
}

.list--closeable-table-header {
    width: 100%;
}

.list--closeable-table-data {
    overflow-y: scroll;
    width: 100%;
}

.list--closeable-table-data-row {
    min-width: calc(100% - 225px) !important;
}

.list--closeable-table-data-row.wide, .list--closeable-table-header.wide {
    min-width: 100% !important;
    display: flex;
    justify-content: space-between;
}

.list--closeable-table-data-row p, .list--closeable-table-header p, .list--closeable-table-data-row a {
    width: 75px;
    min-height: 50px;
    max-height: 100px;
    display: flex;
    align-items: center;
    text-align: left;
}
.list--closeable-table-data-row a {
    color: var(--black);
    font-weight: 500;
}

.list--closeable-table-data-row a:hover {
    text-decoration: none;
    color: var(--obos-blue);
}

/*COLUMN WIDTHS-------------------------------------------------*/
.list .w-250 {
    width: 250px;
}

.list .w-200 {
    width: 200px;
}

.list .w-150 {
    width: 150px;
}

.list .w-125 {
    width: 125px;
}

.list .w-100 {
    width: 100px;
}

.list .w-75 {
    width: 75px;
}

.list .w-60 {
    width: 60px;
}

/*BUTTONS-------------------------------------------------------*/

.list--closeable-table-buttons {
    min-width: 225px;
    max-width: 225px;
}

.list--closeable-table-buttons button {
    box-sizing: border-box;
    height: 30px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    margin: 0 0 0 0;
}

.list--closeable-table-buttons button:last-child {
    margin: 0 10px 0 0;
}