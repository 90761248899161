/*HOW TO USE:
This setup is made to use two button classes together. One button type and one color.
Say you want a regular button with blue background and no border:
classname="btn btn-primary-white-bg"

Or a green popup button on a non white background:
classname="btn-popup btn-secondary"

btn-link should be used on its own, not with a color button

If you want a button custom for a specific component or page, use one of these buttons and alter it in the pages.css
Say a button on the login page
Add button type and color, then in pages.css, customise it there
.login-page .btn-primary {
    login buttons only
}
*/
/*BUTTON TYPES-----------------------------------------------------------------------------------------------------------------------*/
.btn {
    border-radius: 20px;
    padding: 4px 20px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-popup {
    border-radius: 20px;
    width: 100px;
    margin: 0;
    padding: 2px;
    cursor: pointer;
}

.btn-link {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    text-align: left;
    cursor: pointer;
}

/*BUTTON COLORS-----------------------------------------------------------------------------------------------------------------------*/
/*BLUE-----------------------------------------------------------------------------------------------------------------------*/
/*NON WHITE BACKGROUND*/
.btn-primary {
    background-color: var(--obos-blue);
    color: var(--white);
    border: var(--white) solid 3px;
}

.btn-primary.active {
    background-color: var(--white);
    color: var(--obos-blue);
    border: var(--white) solid 3px;
}

.btn-primary.active:hover {
    background-color: var(--white);
    color: var(--dark-blue);
    border: var(--dark-blue) solid 3px;
}

.btn-primary:hover {
    background-color: var(--dark-blue);
    border: var(--white) solid 3px;
}

.btn-primary.active:active {
    background-color: var(--dark-blue) !important;
    border: var(--dark-blue) solid 3px !important;
    color: var(--white) !important;
}

.btn-primary.active:active svg {
    filter: brightness(1000%) !important;
}

/*WHITE BACKGROUND*/
.btn-primary-white-bg {
    background-color: var(--obos-blue);
    color: var(--white);
    border: var(--obos-blue) solid 3px;
}

.btn-primary-white-bg.active {
    background-color: var(--white);
    color: var(--obos-blue);
    border: var(--obos-blue) solid 3px;
}

.btn-primary-white-bg.active:hover {
    background-color: var(--white);
    color: var(--dark-blue);
    border: var(--dark-blue) solid 3px;
}

.btn-primary-white-bg:hover {
    background-color: var(--dark-blue);
    border: solid 3px var(--dark-blue);
}

/*WHITE BACKGROUND DISABLED*/
.btn-primary-white-bg.disabled {
    background-color: var(--disabled-blue);
    color: var(--disabled-blue-light);
    border: var(--disabled-blue) solid 3px;
    cursor: not-allowed;
}

.btn-primary-white-bg.disabled svg {
    filter: brightness(260%) saturate(10%);
}

/*ACTIVE*/
.btn-primary-white-bg.active:hover svg {
    filter: brightness(50%);
}

/*ACTIVE DISABLED*/
.btn-primary-white-bg.active.disabled {
    background-color: var(--disabled-blue-light);
    border: var(--disabled-blue) 3px solid;
    color: var(--disabled-blue);
}

.btn-primary-white-bg.active.disabled .icon--lock-closed-blue {
    filter: brightness(170%) saturate(20%);
}

.btn-primary-white-bg.active.disabled:hover {
    cursor: not-allowed;
}

/*GREEN-----------------------------------------------------------------------------------------------------------------------*/
/*NON WHITE BACKGROUND*/
.btn-secondary {
    background-color: var(--obos-green);
    color: var(--white);
    border: var(--white) solid 3px;
}

.btn-secondary.active {
    background-color: var(--white);
    color: var(--obos-green);
    border: var(--white) solid 3px;
}

.btn-secondary:hover {
    background-color: var(--dark-green);
    border: solid 3px var(--white);
 }

/*WHITE BACKGROUND*/
.btn-secondary-white-bg {
    background-color: var(--obos-green);
    color: var(--white);
    border: var(--obos-green) solid 3px;

}

.btn-secondary-white-bg.active {
      background-color: var(--white);
      color: var(--obos-green);
      border: var(--obos-green) solid 3px;
}

.btn-secondary-white-bg:hover {
    background-color: var(--dark-green);
    border: solid 3px var(--dark-green);
}

.btn-secondary-white-bg.active:hover svg {
    filter: brightness(50%);
}

.btn-secondary-white-bg.disabled {
    background-color: var(--disabled-green);
    color: var(--disabled-green-font);
    border: var(--disabled-green) solid 3px;
    cursor: not-allowed;
}

.btn-secondary-white-bg.disabled svg {
    filter: brightness(165%) saturate(25%);
}

/*IN COMMON*/
.btn-secondary.active:hover, .btn-secondary-white-bg.active:hover {
    background-color: var(--white);
    color: var(--dark-green);
    border: var(--dark-green) solid 3px;
}

/*RED-----------------------------------------------------------------------------------------------------------------------*/
/*NON WHITE BACKGROUND*/
.btn-delete {
    background-color: var(--red);
    color: var(--white);
    border: var(--white) solid 3px;
}

.btn-delete:hover {
    background-color: var(--dark-red);
    border: var(--white) solid 3px;
}

/*WHITE BACKGROUND*/
.btn-delete-white-bg {
    background-color: var(--red);
    color: var(--white);
    border: var(--red) solid 3px;
}

.btn-delete-white-bg:hover {
    background-color: var(--dark-red);
    border: solid 3px var(--dark-red);
}

.btn-delete-white-bg.active {
    background-color: var(--white);
    color: var(--red);
    border: var(--red) solid 3px;
}

.btn-delete-white-bg.active:hover {
    color: var(--dark-red);
    border: var(--dark-red) solid 3px;
}

/*DISABLED*/
.btn-delete-white-bg.disabled {
    background-color: var(--disabled-red);
    color: var(--disabled-red-font);
    border: var(--disabled-red) solid 3px;
    cursor: not-allowed;
}

.btn-delete-white-bg.disabled svg {
    filter: brightness(500%) saturate(45%);
}

/*LINK BUTTON -----------------------------------------------------------------------------------------------------------------------*/
.btn-tetiary {
    color: var(--black);
}

.btn-tetiary:hover {
    text-decoration: none;
}

.btn svg {
    width: 25px;
    height: 25px;
    margin-left: 5px;
}

/*OTHER BUTTON STYLES -----------------------------------------------------------------------------------------------------------------------*/
.refresh-button:hover svg{
    transform: rotate(360deg);
    filter: brightness(60%);
    transition: transform 0.5s ease;
}