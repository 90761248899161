/*
This file is for reusable inputs, including both pre-made elements and custom inputs.
Any unique customization for an input should be done in pages.css, within the component where the input is used.
*/

/*INPUT ACCESSORIES---------------------------------------------------------------------------------------------------*/
label {
    margin-bottom: 4px;
}

::-webkit-input-placeholder {
    font-style: italic;
}

input:-webkit-autofill {
    background-color: white;
    transition: background-color 5000s ease-in-out 0s
}

/*INPUTS ---------------------------------------------------------------------------------------------------*/
/*TEXT/CHARACTER TYPES*/
input[type="text"],
input[type="number"],
input[type="password"],
.input,
textarea {
    box-sizing: border-box;
    height: 50px;
    border-radius: 20px;
    border: 3px solid var(--white);
    padding-left: 10px;
    background-color: var(--white);
    margin-bottom: 20px;
    resize: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
    border: 3px solid var(--obos-blue) !important;
    outline: none;
}

/*CHECKBOX*/
input[type="checkbox"] {
    cursor: pointer;
    appearance: none;
    background-color: var(--white);
    margin: 15px 15px 15px 0;
    width: 22px;
    height: 22px;
    border: 3px solid var(--white);
    border-radius: 0.15em;
    display: grid;
    place-content: center;
}

input[type="checkbox"]:not(:checked):hover::before {
    content: url('~../icons/checkmarks/Checkmark_nb_green.svg');
    width: 30px;
    height: 30px;
}

input[type="checkbox"]:checked:hover::before {
    content: none;
}

input[type="checkbox"]:hover::before {
    content: url('~../icons/checkmarks/Checkmark_nb_blue.svg');
    width: 30px;
    height: 30px;
}

input[type="checkbox"]:checked::after {
    content: url('~../icons/checkmarks/Checkmark_nb_blue.svg');
    width: 30px;
    height: 30px;
}

input[type="checkbox"]:checked:hover::after {
    content: url('~../icons/checkmarks/Checkmark_nb_dark_blue.svg');
}

/*SELECTS*/
select {
    box-sizing: border-box;
    height: 50px;
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 3px solid var(--white);
}


/*CUSTOM INPUTS ---------------------------------------------------------------------------------------------------*/
/*SWITCH*/
.switch {
    position: relative;
    display: inline-block;
    min-width: 45px;
    max-width: 45px;
    height: 26px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--obos-green);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 34px;
}

.custom-select {
    position: relative;
}

/*SELECT*/
.custom-select .dropdown-list {
    position: absolute;
    bottom: 60px;
    box-sizing: border-box;
    background-color: var(--white);
    border-radius: 20px;
    border: solid 3px var(--obos-blue);
    padding: 10px;
    width: 200px;
    max-height: 200px;
    overflow-y: scroll;
}

.custom-select .dropdown-item {
    color: var(--obos-blue);
    font-weight: bold;
    cursor: pointer;
}

.custom-select .dropdown-item:hover {
    color: var(--obos-green);
}

.custom-select .dropdown-item.disabled {
    cursor: not-allowed;
    color: var(--disabled-blue);
}

/*SMS CODE*/
.sms-code--form {
    width: 100%;
}
.sms-code--inputs input {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    margin-bottom: 50px;
    padding: 0!important;
}

.sms-code--form .input-hidden {
    position: absolute;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
}

.sms-code--form .input-hidden:focus {
    border: 3px solid white !important;
}

.sms-code--form.login {
    width: 75%;
    height: 410px;
}

.sms-code--form.login h2 {
    margin-bottom: 30px;
    width: 75%;
}

.sms-code--form.login .sms-code--inputs {
    width: 100%;
}

