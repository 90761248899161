.page-404 {
    color: var(--black);
    text-align: center;
}
.page-404--you {
    margin-top: 100px;
    margin-left: 1000px;
    color: var(--obos-blue);
}

.page-404--you p {
    font-weight: bold;
    margin-bottom: -10px;
    font-size: 14px;
}

.page-404--you svg {
    width: 25px;
    height: 25px;
}
.page-404--message {
    width: 700px;
    margin-top: 60px;
}

.page-404--message img {
    width: 700px;
}

.page-404--message p {
    width: 650px;
}

.page-404--message p, .page-404--message img {
    margin-bottom: 40px;
}

.page-404--message button:hover {
    color: var(--dark-blue);
    background-color: unset !important;
    background-image: url('~../../images/Map.png');
    background-size: cover;
    background-position: center;
}