/*
Naming format: {page}--{component-type}-{optional details}.

Classes are sorted by their placement within the page.
Whenever possible, reuse existing classes across the page.
*/

/*PROFILE GENERAL-------------------------------------------------------------------------------------------------------*/
.profile {
    position: relative;
    height: 100vh;
}

.profile--map-wrapper {
    position: relative;
    width: 100vw;
    height: 150px !important;
}

.profile--map {
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    z-index: 3;
    width: 100vw;
    height: 150px;
}

.snow-overlay {
    position: absolute;
    top: 90px !important;
    left: 0;
    width: 100vw;
    height: 150px !important;
    z-index: 3;
    border-radius: 0 0 20px 20px;
    background: url('../../gifs/snowinggif2.gif') repeat;
}

/*PROFILE SIDE NAV----------------------------------------------*/

.profile--user {
    position: absolute;
    width: 200px;
    top: calc(90px + 50px);
    left: 25px;
    z-index: 3;
    border-radius: 50%;
    margin-left: 25px;
    margin-bottom: 20px;
}

.profile--user img {
    border-radius: 50%;
}

.profile--user h2 {
    width: 200px;
    height: 80px;
    color: var(--white);
}

.profile--body {
    height: calc(100vh - 90px);
    width: 100%;
    position: absolute;
    bottom: 0;
}

.profile--nav {
    box-sizing: border-box;
    z-index: 2;
    height: 100%;
    width: 300px;
    position: relative;
    background-color: var(--obos-blue);
    border-radius: 0 0 20px 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.profile--nav-buttons {
    position: absolute;
    top: calc( 150px + 180px);
    left: 50px;

}

.profile--nav button {
    width: 200px;
    margin: 15px 0;
    font-size: 18px;
    border: 3px solid var(--white);
}

.profile--nav button.active {
    width: 200px;
    margin: 15px 0;
    font-size: 18px;
    border: 3px solid var(--white);
}

.profile--nav button.active:hover {
    border: 3px solid var(--white);
}

/*PROFILE DISPLAY---------------------------------------------*/
/*These are reused classes that all or most of the profile displays use*/

.profile--display {
    box-sizing: border-box;
    height: calc(100% - 150px);
    margin-top: 150px;
    background-color: var(--white);
    width: calc(100vw - 300px);
}

.profile--display-page, .profile--display-page-dashboard {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
    margin-top: 0!important;
    padding-left: 50px;
}

.profile--display-page {
    padding-right: 50px;
}

.profile--display-page.with-nav {
    padding-top: 80px;
}

.profile--display-header {
    width: 100%;
    height: 40px !important;
    margin-top: calc(25px + 10px);
}

.profile--display-header-no-h2 {
    margin-bottom: 15px;
    height: 80px;
    margin-top: 15px;
}

.profile--display-header-notifications {
    width: 100%;
    height: 60px;
    color: var(--obos-blue);
}

.profile--display-header h2, .profile--display-header-notifications h2 {
    margin: 0 !important;
    width: 300px !important;
}

.profile--display-header-message {
    width: calc(100% - 300px);
    margin-left: 10px;
    box-sizing: border-box;
    margin-bottom: 0!important;
}
.profile--display-header-no-h2 .profile--display-header-message {
    width: 100%;
    box-sizing: border-box;
    margin: unset !important;
    padding: unset !important;
}

.profile--display-header-message .info-message {
    box-sizing: border-box;
    height: 60px;
    width: 100%;
}

.profile--display-body {
    margin-top: 30px;
    width: 100%;
    padding: 0 !important;
}

.profile--display-body.higher {
    margin-top: 15px;
}

.profile--display-body.higher {
    box-sizing: border-box;
    width: 100%;
    height: 475px;
    overflow-y: auto;
    padding: 15px;
}

/*PROFILE DISPLAYS------------------------------------------------------------------------------------------------------*/
/*These are the actual displays and their individual classes*/
.profile--admin-new-user, .profile--developer-add-update, .profile--admin-edit-user, .profile--display-header-message {
    box-sizing: border-box;
    padding: 10px;
}

.profile--admin-nav, .profile--developer-nav, .profile--feedback-nav {
    height: 40px !important;
}

.profile--admin-nav button, .profile--developer-nav button {
    height: 40px;
    width: 200px;
}

.profile--feedback-nav button {
    height: 40px;
    width: 275px;
}

.profile--admin-nav-new {
    position: absolute;
    top: calc(130px);
    left: 300px !important;
    width: calc(100vw - 300px);
    z-index: 2;
}

.profile--admin-nav-new button {
    padding-top: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    background-color: var(--obos-green);
    color: var(--white);
    border: 3px solid var(--obos-green);
    cursor: pointer;
    position: relative;
}

.profile--admin-nav-new button:hover:not(button.active) {
    background-color: var(--dark-green);
    border: solid 3px var(--dark-green);
    box-sizing: border-box;
}

.profile--admin-nav-new button:hover ~ .profile--admin-nav-new {
    background-color: var(--dark-green);
}

.profile--admin-nav-new button.active {
    box-sizing: border-box;
    border-top: solid 3px var(--obos-green);
    border-bottom: 3px solid var(--white);
    font-weight: 700;
}

/*DASHBOARD------------------------------------------------*/
/*COMMON*/
.profile--dashboard--notifications h2,
.profile--dashboard--notifications-big-screen h2,
.profile--dashboard--updates h2 {
    height: 40px;
    margin-bottom: 15px;
    text-align: left;
    width: fit-content;
}

.profile--dashboard--all-notifications,
.profile--dashboard--all-updates {
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
    height: calc(100% - 40px) !important;
}

.profile--dashboard--updates .profile--dashboard-update,
.profile--dashboard--notifications .profile--dashboard-notification,
.profile--dashboard--notifications-big-screen .profile--dashboard-notification {
    box-sizing: border-box;
}

/*UPDATES*/
.profile--dashboard--updates {
    max-height: calc(100%);
    overflow-y: scroll;
    max-width: 1000px;
    transition: max-width 1s ease;
    padding: 0 20px;
}

.profile--dashboard--updates ::-webkit-scrollbar {
    display: none;
}

.profile--dashboard-update:first-of-type {
    margin-top: 50px;
}

.profile--dashboard-update {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    margin: 0 0 20px 0;
    transition: max-width 1s ease;
}

.profile--dashboard-update b,
.profile--dashboard-update h3 {
    color: var(--obos-blue);
}

.profile--dashboard-update button {
    margin-top: 10px;
    box-shadow: unset !important;
}

.profile--dashboard--updates.slim {
    max-width: calc(100% - 600px);
}

.profile--dashboard--updates.slim .profile--dashboard-update {
    max-width: 90%;
}

.profile--dashboard--updates.wide-input {
    max-width: calc(100% - 40px);
}

.profile--dashboard--updates.wide-input .profile--dashboard-update {
    max-width: 900px;
}

.profile--dashboard--updates .profile--dashboard-update p:nth-child(3) {
    color: var(--black);
}

.profile--dashboard--updates .profile--dashboard-update p:nth-child(4) {
    color: var(--obos-green);
    filter: brightness(80%);
}

/*NOTIFICATIONS*/
.profile--dashboard--notifications,
.profile--dashboard--notifications-big-screen {
    padding-top: 10px;
    box-sizing: border-box;
    background-color: var(--light-blue);
    height: calc(100% - 50px);
    border-radius: 20px 0 0 20px;
}

.profile--dashboard--notifications-big-screen {
    padding-left: 40px;
}

.profile--dashboard--notifications-container {
    height: 100%;
}

.profile--dashboard--all-notifications {
    height: calc(100% - 60px) !important;
    padding: 0 10px;
}

.profile--dashboard-notification {
    border-radius: 20px;
    width: 495px;
    background-color: var(--white);
    padding: 10px;
    margin-bottom: 10px;
}

.profile--dashboard-notification p:first-child,
.profile--dashboard-notification .date {
    font-weight: bold;
}

.profile--dashboard-notification .triggeruser {
    color: var(--obos-blue);
    border-radius: 20px 0 0 20px;
    width: 120px;
}

.profile--dashboard-notification .triggeruser.you {
    color: var(--obos-green);
}

.profile--dashboard-notification .message {
    width: 240px !important;
    height: 60px;
    color: var(--black);
}

.profile--dashboard-notification .date {
    width: 100px;
    font-size: 12px;
    color: var(--obos-green);
    filter: brightness(80%);
    text-align: center;
}

/*SVG*/
.profile--dashboard--notifications svg {
    margin: 10px;
    height: 40px !important;
    width: 40px !important;
    transform: rotate(90deg);
    transition: transform 1.5s ease;
    cursor: pointer;
}

.profile--dashboard--notifications svg:hover {
    filter: brightness(70%);
}

.profile--dashboard--notifications.open svg {
    transform: rotate(270deg);
    transition: transform 1.5s ease;
}

/*OPEN CLOSED STATE NOTIFICATION*/
.profile--dashboard--notifications .dashboard--notifications-container {
    max-width: 0;
    overflow: hidden;
    transition: max-width 1.5s ease;
}

.profile--dashboard--notifications.open {
    box-sizing: border-box;
    max-width: 600px;
}

.profile--dashboard--notifications.open .profile--dashboard--all-notifications {
    width: 540px;
    box-sizing: border-box;
    transition: max-width 1.5s ease;
}

.profile--dashboard--notifications.open .dashboard--notifications-container {
    width: 580px;
    max-width: 580px;
    padding-right: 20px;
    transition: max-width 1.5s ease;
    box-sizing: border-box;
}

.profile--dashboard--notifications.open .dashboard--notifications-container ::-webkit-scrollbar {
    display: block;
}

.profile--dashboard--notifications .dashboard--notifications-container ::-webkit-scrollbar {
    display: none;
}

/*BIG SCREEN NOTIFICATION*/
.profile--dashboard--notifications-big-screen {
    overflow-y: hidden;
}
.profile--dashboard--notifications-big-screen .dashboard--notifications-container {
    width: 1400px;
    max-width: 1400px;
    padding-right: 20px;
    transition: max-width 1.5s ease;
    box-sizing: border-box;
    height: 100% !important;
    overflow-y: hidden;
}

.profile--dashboard--notifications-big-screen .dashboard--notifications-columns {
    overflow-y: scroll;
    height: 100% !important;
    width: 100%;
}

.profile--dashboard--notifications-big-screen .profile--dashboard--all-notifications {
    padding-top: 0 !important;
    min-width: 400px;
    max-width: 800px;
}

.profile--dashboard--notifications-big-screen .profile--dashboard--all-notifications:first-child {
    margin-right: 10px;
}

.profile--dashboard--notifications-big-screen .profile--dashboard-notification {
    width: 375px;
}

.profile--dashboard--notifications-big-screen .profile--dashboard-notification .message {
    width: 150px;
}

.profile--dashboard--notifications-big-screen .profile--dashboard-notification .date {
    width: 80px;
}

/*CHANGE USER ------------------------------------------------*/
.profile--edit-user-form {
    width: calc(50% - 10px);
}

.profile--edit-user-form .

.profile--edit-user-form input {
    width: calc(100%);
}

.profile--display-page .sms-code--form {
    margin-top: 20px;
}

/*FEEDBACK ----------------------------------------------------*/
/*SEND FEEDBACK*/
.profile--send-feedback-form select {
    width: calc(50% - 10px);
}

.profile--send-feedback-form textarea {
    width: 100%;
    height: 150px;
}

.profile--send-feedback-form {
    margin-bottom: 10px;
}

/*SEE SENDT FEEDBACK*/
.feedback--header {
    margin: 0 13px;
}

.feedback--header p:first-child, .feedback--data .feedback p:first-child  {
    padding-left: 10px;
}
.feedback--header p, .feedback--data p {
    width: 150px;
}

.feedback--header p:nth-child(2), .feedback--data p:nth-child(2) {
    width: calc(100% - 600px);
}

.feedback--data {
    height: calc(60px * 6);
    overflow-y: scroll;
    width: 100%;;
}

.feedback--data .feedback {
    box-sizing: border-box;
    height: 60px;
    margin: 10px;
}

.feedback--data .feedback.positiv p:first-child,
.feedback--data .feedback.bug p:first-child,
.feedback--data .feedback.forslag p:first-child,
.feedback--data .feedback.kritisk p:first-child
{
    font-weight: 700;
}

.feedback--data .feedback.positiv {
    border: 3px solid var(--obos-green);
}
.feedback--data .feedback.positiv p:first-child {
    color: var(--obos-green);
}

.feedback--data .feedback.bug {
    border: 3px solid var(--yellow);
}
.feedback--data .feedback.bug p:first-child {
    color: var(--yellow);
}

.feedback--data .feedback.forslag {
    border: 3px solid var(--obos-blue);
}
.feedback--data .feedback.forslag p:first-child {
    color: var(--obos-blue);
}

.feedback--data .feedback.kritisk {
    border: 3px solid var(--red);
}
.feedback--data .feedback.kritisk p:first-child {
    color: var(--red);
}

.feedback--data .feedback-none {
    width: 300px;
}

/*ADMIN------------------------------------------------*/
.profile--admin-all-users, .profile--developer-see-feedback {
    height: 450px;
    overflow-y: scroll;
    padding: 5px;
    margin-top: 60px;
}

.profile--admin-all-users .user p, .feedback--header p, .feedback--data p, .profile--admin-handle-data-closeable-container {
    color: var(--black);
}

.profile--admin-all-users .user p, .feedback--header p, .profile--admin-handle-data-closeable-header h4 {
    font-weight: 700;
}

/*ALL USER*/
.profile--admin-all-users .user, .profile--admin-all-users button {
    margin-bottom: 20px;
    height: 60px;
}

.profile--admin-all-users .user {
    box-sizing: border-box;
    width: calc(100% - 115px);
    color: var(--black);
    font-weight: 700;
    padding: 0 20px;
    margin-left: 5px;
}

.profile--admin-all-users button {
    width: 100px;
}

/*CREATE USER & EDIT USER*/
.profile--admin-new-user div div, .profile--admin-edit-user div div {
    width: calc(50% - 10px);
}

.profile--admin-new-user input, .profile--admin-new-user select,
.profile--admin-edit-user input, .profile--admin-new-user select {
    width: calc(100%);
}

.profile--admin-new-user .input-disabled, .profile--admin-edit-user .input-disabled {
    cursor: not-allowed;
}

.profile--admin-edit-user input[type="checkbox"] {
    width: 34px;
    height: 34px;
    margin: 8px 0;
    border-radius: 10px;
}

.profile--admin-edit-user p {
    color: var(--black);
    margin-left: 10px;
}

/*HANDLE DATA*/
.profile--admin-handle-data div {
    width: calc(50% - 10px);
}

.profile--admin-handle-data form, .profile--admin-handle-data-containers {
    padding: 10px 5px;
}

.profile--admin-handle-data form input, .profile--admin-handle-data form select {
    width: calc(100%);
}

.profile--admin-handle-data form div {
    width: 100%;
}

.profile--admin-handle-data-note {
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
    width: calc(100% - 10px);
    border-radius: 25px;
    border: 3px solid var(--obos-green);
    font-size: 14px;
    text-align: center;
    font-weight: 700;
}

.profile--admin-handle-data-containers {
    box-sizing: border-box;
    max-height: 50vh;
    overflow-y: scroll;
    width: 100% !important;
}

.profile--admin-handle-data-closeable-header {
    width: 100% !important;
    cursor: pointer;
}

.profile--admin-handle-data-closeable-header h4 {
    height: 40px !important;
}

.profile--admin-handle-data-closeable-header svg {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}

.profile--admin-handle-data-closeable-container {
    width: 100% !important;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 10px;
    height: auto;
    transition: height 0.5s ease;
}

.profile--admin-handle-data-closeable {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease;
    width: 100% !important;
}

.profile--admin-handle-data-closeable div {
    width: 100% !important;
    max-height: 40px !important;
    min-height: 40px !important;
}

.profile--admin-handle-data-closeable div button {
    height: 30px;
    margin-right: 5px;
    box-shadow: none;
}

.profile--admin-handle-data-closeable-container:not(:first-of-type) {
    margin-top: 10px;
}

/* Open state */

.profile--admin-handle-data-closeable-container.open .profile--admin-handle-data-closeable {
    max-height: 200px;
    overflow-y: scroll;
    transition: max-height 0.5s ease;
    width: 100% !important;
}

.profile--admin-handle-data-closeable-container.open svg {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
}

/*DEVELOPER ------------------------------------------------*/
/*SEE FEEDBACK*/

/*POST UPDATE*/
.profile--developer-add-update select, .profile--developer-add-update input {
    width: calc(50%);
}

.profile--developer-add-update textarea {
    width: calc(100%);
    height: 100px;
}

/*SUBSCRIPTIONS*/
.profile--subcriptions-buttons {
    width: 100%;
    margin-bottom: 30px;
}

.profile--subcriptions-buttons svg {
    height: 40px;
    width: 40px;
    cursor: pointer;
    box-sizing: border-box;
}

.profile--subcriptions-buttons svg:hover {
    border: 3px solid var(--obos-green);
}

.profile--subcriptions-buttons svg.active {
    border: 3px solid var(--obos-blue);
}

.profile--subcriptions-buttons svg:not(.icon--kum-wall) {
    border-radius: 100%;
}

.profile--subcriptions-buttons .icon--kum-wall {
    border-radius: 17%;
}

.profile--subscription, .subscription-none {
    position: relative;
    background-color: var(--white);
    border-radius: 20px;
    box-sizing: border-box;
    height: 50px;
    margin-bottom: 10px;
    padding: 0 10px;
    color: var(--black);
    font-weight: bold;
}

.profile--subscription p {
    color: var(--black);
    font-weight: bold;
    width: 200px;
}

.profile--subscription .subscription-name {
    cursor: pointer;
}

.profile--subscription .subscription-name, .profile--subscription svg {
    margin-right: 15px;
}

.profile--subscription svg {
    height: 30px;
    width: 30px;
}

.profile--subscription div {
    width: 400px;
    text-align: left;
}

.profile--subscription .subscription-name:hover {
    color: var(--obos-blue) !important;
}

.profile--subscription-help {
    display: none;
    position: absolute;
    background-color: var(--white);
    color: var(--obos-blue);
    font-weight: unset;
    padding: 10px;
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    width: 200px;
    height: auto;
    right: 0;
    bottom: 50px;
}

.profile--subscription .switch:hover + .profile--subscription-help {
    display: block;
}