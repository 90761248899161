.header--nli, .header {
    height: 90px !important;
}
.header--nli {
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    background-color: var(--white);
    z-index: 1000;
}

.header--nli p, .header p {
    height: 14px;
    margin-top: 0;
    font-size: 14px;
    color: var(--font-blue);
}

/*OBOS LOGO -----------------------------------------------------------------------------------------------------------------------*/
.header--obos-logo {
    height: 55px !important;
    width: auto;
    padding-right: 10px;
    border-right: 3px solid var(--obos-blue);
    margin: 0 10px 0 0 !important;
}

/*SPOTS LOGO -----------------------------------------------------------------------------------------------------------------------*/
.header--spots h3, .header--spots p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header--spots h3 {
    color: var(--obos-blue);
    font-size: 22px;
    height: 22px;
}
.header--spots p {
    height: 14px !important;
}

.header--spots-logo {
    height: 40px !important;
}

/*LOGGED IN BUTTONS -----------------------------------------------------------------------------------------------------------------------*/

.header {
    padding-left: 30px;
    padding-right: 30px;
    background-color: var(--white);
    position: relative;
    z-index: 1000;
}

.header--buttons {
    width: 40%;
}

.header--buttons a {
    height: 20px;
    color: var(--obos-blue);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header--buttons a svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    transition: transform 0.3s ease;
    filter: brightness(100%);
}

.header--buttons a:hover .icon--chat-unread {
    filter: unset !important;
    background-image: url('~../icons/header/Chat_unread_blue-dark.svg');
}

/*HOVER*/

.header--buttons a:hover {
    color: var(--dark-blue);
}

.header--buttons a:hover svg {
    filter: brightness(50%)
}

/*SPESIFIC ICON ANIMATIONS-----------------------------------------------------------------------------------------------------------------*/
.header--buttons a:hover .icon--compass {
    transform: rotate(180deg);
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(-10deg); }
    75% { transform: rotate(10deg); }
    100% { transform: rotate(0deg); }
}

.header--buttons a:hover .icon--profile {
    animation: wiggle 0.5s ease;
}