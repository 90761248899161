/*
Naming format: {page}--{component-type}-{optional details}.

Classes are sorted by their placement within the page.
Whenever possible, reuse existing classes across the page.
*/

.chat {
    background-color: var(--light-blue);
    height: 100vh;
}

.chat--no-open-chat {
    color: var(--obos-blue);
    background-color: white;
    height: 120px;
    padding: 0 20px;
    border-radius: 20px;
}

/*CHAT ALL SESSIONS-------------------------------------------------------------------------------------------------------*/
.chat--all {
    background-color: var(--obos-blue);
    color: white;
    height: calc(100vh - 90px);
    width: 25vw;
    border-radius: 0 20px 20px 0;
    padding: 30px;
    box-sizing: border-box;
}

.chat--users-search, .chat--unread-switch {
    margin-bottom: 20px;
}

/*SEARCH-------------------------------------------------------------------------------------------------------*/
.chat--users-search {
    background-color: var(--white);
    border-radius: 20px;
    height: 50px;
    width: 300px;
    box-sizing: border-box;
}

.chat--users-search input {
    box-shadow: none;
    height: 50px;
    margin: 0 0 0 10px;
    padding: 0;
}

.chat--users-search input:focus {
    border: solid 3px var(--white) !important;
}

.chat--users-search svg {
    height: 36px;
    width: 36px;
    margin-right: 10px;
}
/*TOGGLE UNREAD-------------------------------------------------------*/
.chat--unread-switch {
    width: 300px;
}

.chat--unread-switch p {
    height: 21px;
}
.chat--unread-switch .switch {
    margin-right: 10px;
    height: 21px;
    min-width: 40px;
    max-width: 40px;
    margin-bottom: 0 !important;
    border-radius: 30px;
}

.chat--unread-switch .slider:before {
    height: 14px;
    width: 14px;
}
/*SESSIONS-------------------------------------------------------*/
.chat--all-sessions {
    height: 550px;
    overflow-y: scroll;
    width: 300px;
}

.chat--session {
    box-sizing: border-box;
    height: 80px;
    padding: 10px;
    border: 3px solid var(--obos-blue);
    border-radius: 20px;
    margin: 7px 0;
    cursor: pointer;
    width: 100%;
}

.chat--session-info {
    width: calc(100% - 75px);
}

.chat--session h4 {
    font-size: 16px;
}

.chat--session p {
    color: var(--light-blue) !important;
    font-size: 14px;
}

.chat--session:hover {
    border: 3px solid var(--white);
}

.chat--session.chosen {
    background-color: var(--dark-blue);
    border: 3px solid var(--white);
}

.chat--session .chat--session-icons .icon--new-message {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.chat--session .chat--session-icons .chat--hour-since {
    width: 45px;
    height: 20px;
    margin-left: 5px;
    background-color: var(--dark-blue);
    color: var(--obos-blue) !important;
    font-weight: bold;
    border-radius: 20px;
}

.chat--session.chosen .chat--session-icons .chat--hour-since {
    background-color: var(--obos-blue);
    color: var(--dark-blue) !important;
}

.chat--no-matches {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--dark-blue);
    height: 80px;
    border: 3px solid var(--white);
    border-radius: 20px;
    padding: 0 10px;
}

/*CHAT ALL MESSAGES-----------------------------------------------------------------------------------------------------*/
.chat--messages-container {
    height: 550px;
    overflow-y: scroll;
    width: 716px;
    margin: 0 0 30px 0;
}

.chat--date-label {
    text-align: center;
    color: var(--obos-blue);
    opacity: 75%;
    margin-bottom: 10px;
    font-size: 18px;
}

.chat--message {
    box-sizing: border-box;
    width: 100%;
    padding: 0 8px;
}

.chat--message:not(:last-child) {
    margin-bottom: 10px;
}

.chat--message .message {
    min-height: 20px;
    width: fit-content;
    max-width: 500px;
    padding: 10px 20px;
    border-radius: 20px;
}

.chat--message.you {
    align-items: flex-end;
}

.chat--message.you .message {
    background-color: var(--obos-blue);
    color: white;
}

.chat--message.otherUser .message{
    background-color: var(--white);
    color: var(--black);
}

.chat--message p:nth-child(2) {
    color: var(--obos-blue);
    font-size: 12px;
    font-weight: bold;
    opacity: 75%;
}

.chat--tagged-object {
    color: var(--obos-blue);
    font-weight: bold;
    cursor: pointer;
}

.chat--message.otherUser .chat--tagged-object:hover {
    color: var(--obos-green);
}

.chat--message.you .chat--tagged-object {
    color: var(--white);
}

.chat--message.you .chat--tagged-object:hover {
    color: var(--obos-green);
}

.chat--no-messages {
    background-color: var(--white);
    height: 70px;
    width: 450px;
    border-radius: 20px;
    color: var(--obos-blue);
    font-weight: bold;
}
.chat--open {
    height: calc(100vh - 90px);
    width: 75vw;
}
/*SEND MESSAGE-------------------------------------------------------*/
.chat--input-container {
    background-color: white;
    border-radius: 20px;
    height: 70px;
    width: 700px;
}

.chat--input {
    box-shadow: unset;
    width: calc(100% - 100px);
    box-sizing: border-box;
    height: 70px !important;
    padding: 0 10px;
    min-height: 40px;
    margin: 0 !important;
    border: 3px solid var(--white) !important;
}

.chat--input-container input:focus {
    border: unset !important;
    outline: none !important;
    box-shadow: none !important;
}

.chat--input-buttons {
    position: relative;
}

.chat--input-buttons svg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin: 0 5px;
}

.chat--help-box {
    visibility: hidden;
    position: absolute;
    background-color: var(--white);
    border-radius: 20px;
    border: 3px solid var(--obos-blue);
    padding: 15px;
    box-sizing: border-box;
    width: 225px;
    text-align: center;
    color: var(--obos-blue);
    font-size: 12px;
    bottom:70px;
    right: -20px;
    line-height: 18px;
}

.chat--input-buttons .icon--tag-blue:hover + .chat--help-box {
    visibility: visible;
}

.chat--search-box {
    box-sizing: border-box;
    position: absolute;
    bottom: 70px;
    right: -20px;
    width: 250px;
    height: auto;
    max-height: 250px;
    overflow: hidden;
    background-color: var(--white);
    border-radius: 20px;
    border: 3px solid var(--obos-blue);
}

.chat--search-box input {
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 20px 20px 0 0;
    width: 100%;
    margin: 0 !important;
}

.chat--search-results-container {
    overflow-y: auto;
    padding-bottom: 10px;
}

.chat--search-results {
    max-height: 180px;
    overflow-y: auto;
}

.chat--search-results p {
    box-sizing: border-box;
    padding-left: 10px;
    height: 30px !important;
    font-weight: bold;
    color: var(--obos-blue);
    cursor: pointer;
    line-height: 30px;
    font-size: 14px;
}

.chat--search-results p:hover {
    color: var(--obos-green);
}

.chat--search-results svg {
    height: 20px;
    width: 20px;
}