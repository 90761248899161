@import url('font.css');
@import url('colors.css');
@import url('icons.css');
@import url('buttons.css');
@import url('messages.css');
@import url('inputs.css');
@import url('headers.css');

@import url('pages/map.css');
@import url('pages/list.css');
@import url('pages/info.css');
@import url('pages/chat.css');
@import url('pages/profile.css');
@import url('pages/404.css');
@import url('pages/login.css');

/*
If a new CSS file is created, it must be imported here (see examples above).

General styles for common HTML elements and reusable utility classes used throughout the application.
This file does not include component-specific styles, but rather focuses on reusable elements like inputs, margins, typography, etc.
*/

* {
    margin: 0;
    padding: 0;
}

body {
    width: 100vw;
    overflow-x: hidden;
}

#root {
    margin: 0;
    height: 100vh;
    color: var(--black);
}

.leaflet-control-attribution {
    display: none;
}

/*SCROLLBAR -----------------------------------------------------------------------------------------------------------------------*/
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    display: none;
}

::-webkit-scrollbar-thumb {
    background: var(--obos-blue);
    border-radius: 6px;
}

/* SHADOW-----------------------------------------------------------------------------------------------------------------------*/
/*
Do not add shadow directly within individual classes. Instead, define the shadow-related
classes here to maintain consistency. This allows for easier updates, as all shadow styles
can be adjusted in one place. Classes are organized by name and page for clarity.
*/

.header,
.login--content,
.info-banner,
.helping-box,

.map--filter,
.map--side-table-closeable,
.map--filter-toggle .switch .slider,
.map--search .input, .map--search-address .input, .map--add-ports-display,
.map--add,
.map--help,
.choose-port--chosen-ports,
.custom-alert,
.map--add-search-display,
.map--update-coordinate--help-box h4,
.map--update-coordinate--help-box-text,

.list--closeable,
.list--content-container,
.list--statistics,

.info-page--content-header .subscribe.disabled .info-text,
.info-page--name svg,
.info-page--name-input svg,
.info-page--content,
.info-page--log,
.info-page--data,
.info-page--files,
.info-page--files-fullscreen-container img,
.info-page--files-fullscreen-container .pdf,
.info-page--files-fullscreen-buttons,
.info-page--connections,
.info-page--switches,
.info-page--connections-nodes,

.chat--all,
.chat--input-container,
.chat--help-box,
.chat--session:hover,
.chat--session.chosen,
.chat--unread-switch .switch,
.chat--message .message,
.chat--search-box,
.chat--no-open-chat,
.chat--session .chat--session-icons .icon--new-message,
.chat--session .chat--session-icons .chat--hour-since,
.chat--no-messages,
.chat--users-search,
.chat--no-matches,

.popup-opacity-background .delete-popup-content,
.popup-opacity-background .choose-port--message,

.profile--map,
.profile--user img,
.profile--nav,
.profile--dashboard--notifications,
.profile--dashboard--notifications-big-screen,
.profile--dashboard--updates .profile--dashboard-update,
.profile--dashboard--notifications .profile--dashboard-notification,
.profile--dashboard--notifications-big-screen .profile--dashboard-notification,
.profile--subcriptions-buttons svg,
.profile--subscription,
.subscription-none,
.profile--admin-handle-data-closeable-container, .profile--admin-handle-data-note,
.profile--admin-nav-new,

.info-message,
input:not(.map--filter--checkbox input, .input input),
select,
textarea,
.btn:not(.choose-port--message button, .header button, .list--closeable-table-buttons button, .profile--dashboard--all-updates button, .delete-popup-content button, .profile--admin-handle-data-closeable button),
.profile--admin-all-users .user,
.feedback--data .feedback {
    box-shadow: 2px 5px 7px rgba(0, 0, 0, 0.2);
}

/* FLEXBOX LAYOUTS -----------------------------------------------------------------------------------------------------------------------*/
/* Styles for flex container properties like display, justify-content, and align-items
   to manage layout and alignment across the application */
.d-flex {
    display: flex;
}

.flex--column {
    display: flex;
    flex-direction: column;
}

.flex--row {
    display: flex;
    flex-direction: row;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-se {
    justify-content: space-evenly;
}

.justify-content-sb {
    justify-content: space-between;
}

.justify-content-right {
    justify-content: flex-end;
}

.justify-content-left {
    justify-content: left;
}

.align-text--left{
    text-align: left;
}

.align-text--center {
    text-align: center;
}

.align-items--center {
    align-items: center;
}

.align-items--left {
    align-items: flex-start;
}

/*SIZES -----------------------------------------------------------------------------------------------------------------------*/
.w-22vw {
    width: 22vw;
}

.w-25vw {
    width: 25vw;
}

.w-75vw {
    width: 75vw;
}

.w-78vw {
    width: 78vw;
}

.w-50vw {
    width: 50vw;
}

.w-100vw {
    width: 100vw;
}

.w-100per {
    width: 100%;
}

.w-620px {
    width: 620px;
}

.h-auto {
    height: auto;
}

.h-150px {
    height: 150px;
}

.h-175px {
    height: 175px;
}

.h-10vh {
    height: 10vh;
}

.h-20vh {
    height: 20vh;
}

.h-90vh {
    height: 90vh;
}

.h-100vh {
    height: calc(100vh - 90px);
}

/*MARGINS AND PADDINGS -----------------------------------------------------------------------------------------------------------------------*/
.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-10px {
    margin-bottom: 10px;
}

.m-b-20px {
    margin-bottom: 20px;
}

.m-b-30px {
    margin-bottom: 30px;
}

.m-10px {
    margin: 10px;
}

.m-l-10px {
    margin-left: 10px;
}

.m-l-20px {
    margin-left: 20px;
}

.m-l-40px {
    margin-left: 40px;
}

.m-l-50px {
    margin-left: 50px;
}

.m-r-0 {
    margin-right: 0 !important;
    margin-left: auto;
}

.m-r-10px {
    margin-right: 10px;
}

.m-r-20px {
    margin-right: 20px;
}

.m-t-10px {
    margin-top: 10px;
}

.m-t-30px {
    margin-top: 30px;
}

.m-t-40px {
    margin-top: 40px;
}

.m-s-auto {
    margin-right: auto;
    margin-left: auto;
}

.p-30px {
    padding: 30px;
}

.p-50px {
    padding: 50px;
}

.p-tb-100px {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*BORDERS-----------------------------------------------------------------------------------------------------------------------*/
/*BLUE*/
/*TBA LIKE SHADOWS FOR BORDERS*/

/*RADIUS*/
.br {
    border-radius: 20px;
}